<template>
    <div class="">
        <modal-tool-damage-confirm :thisModal="modalToolDamageConfirm" modalId="modalToolDamageConfirm" title="Transfer" :toolDamage="selectedToolDamage" @confirmed="confirmedToolDamage" />
        <h2>Oštećeni alati <span @click="refreshData()"><font-awesome-icon icon="sync" /></span></h2>
        <div>
            <ejs-grid
                ref="allToolDamages"
                :dataSource="allToolDamages"
                class="all-tool-damages-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :filterSettings='filterSettings'
                :allowPaging='paginatorVissible' 
                :pageSettings='pageSettings'

                :allowSelection='false'
                :enableHover='true'
                :enableHeaderFocus='true'

                :recordClick="damageClick"
                :allowResizing='true'
                :allowTextWrap='true'
                :queryCellInfo='customiseCell'


                >
                <e-columns>
                    <e-column field='id' headerText='ID' width='120' textAlign='Right' :isPrimaryKey='true' :visible='false' ></e-column>

                    <e-column field='tool_obj.name' headerText='Naziv' width='120' textAlign='Left' :visible='true' :template="toolsNameTemplate"></e-column>
                    <e-column field='tool_obj.identification' headerText='Br' width='60' textAlign='Left' :visible='false'></e-column>
                    <e-column field='reported_by_obj.username' :valueAccessor='fullnameAccessor' headerText='Prijavio' minWidth='70' width='100' textAlign='Left' :visible='true'></e-column>
                    <e-column field='damaged_by_obj.username' :valueAccessor='fullnameAccessor' headerText='Oštetio' minWidth='70' width='100' textAlign='Left' :visible='true'></e-column>
                    
                    <e-column field='damage_status_display' headerText='Status' minWidth='75' width='60' textAlign='Left' :visible='true'></e-column>



                    <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Poslednja izmena' width='108' format="yMd HH:mm" textAlign='Right' :visible='true'></e-column>
                    <e-column field='modified_by.username' :valueAccessor='fullnameAccessor' headerText='Poslednji izmenio' width='130' :visible='false'></e-column>
                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Kreirano' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='created_by.username' :valueAccessor='fullnameAccessor' headerText='Kreirao' width='130' :visible='false'></e-column>


                </e-columns>
            </ejs-grid>    
        </div>
    </div>
    
</template>


<script>

    import {mapState} from 'vuex'
    // import RequestStatus from '@/components/RequestStatus.vue';
    // import { Modal } from 'bootstrap';
    import UtilService from '@/service/UtilService.js';

    import ToolsService from '@/service/ToolsService.js';
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize } from "@syncfusion/ej2-vue-grids";
    import { Modal } from 'bootstrap'
    // import ToolTypesButtons from '@/components/tools/ToolTypesButtons.vue';
    // import ToolsWarehouseButtons from '@/components/tools/ToolsWarehouseButtons.vue';
    import ToolTransfersCMD from '@/components/grid-templates/ToolTransfersCMD.vue';
    import ModalToolDamageConfirm from '@/components/modals/tools/ModalToolDamageConfirm.vue';


    export default {
        components: {
            ModalToolDamageConfirm
        },
  
       data() {
            return {
                modalToolDamageConfirm: null,


                allTools: [],
                allToolDamages: [],


                toolType: undefined,
                warehouse: undefined,
                selectedToolDamage: undefined,
                intervalRefresh: undefined,
                refreshCount: 0,

                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },


                toolsNameTemplate: function() {
                    return {
                        template: {
                            extends: ToolTransfersCMD,
                            propsData: {
                                column: "TOOL_NAME"
                            },   
                        }
                    };
                },


                // toolTransfersCMD: function() {
                //     return {
                //         template: {
                //             extends: ToolTransfersCMD,
                //             propsData: {
                //                 column: "CMD"
                //             },
                            
                //         }

                //     };
                // },


                
            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize ]
        },

        computed: {
            ...mapState([
                'user',
            ]),

            isAdmin() {
                if (this.user.role === 'ADMIN') return true;
                return false;
            },

            isManager() {
                if (this.user.role === "MANAGER" || this.user.role === "ADMIN" || this.userAssignment?.pwo?.pwo_role == 1)
                    return true;
                return false;
            },

            paginatorVissible() {
                if (this.allToolDamages?.length > 20) return true;
                return false;
            }
        },


        

        methods: {
            
            formatDate: UtilService.formatDate,
            formatDateTime: UtilService.formatDateTime,
        
            fullnameAccessor(field, data) {
                let firstField = field.split('.')[0];
                let userData = data?.[firstField];
                console.log(userData);
                if (!userData?.first_name) return;
                return userData.first_name + " " + userData.last_name; 
            },

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },



            customiseCell(args) {
                if (args.data?.damage_status === 'REPORTED') {
                    args.cell.classList.add('reported-status');
                }
            },

            loadAllReportedTools() {
                let query = {
                    status: ["REPORTED"],
                }

                ToolsService.getAllToolsDamages(query).then(async (result)=>{
                    this.allToolDamages = ToolsService.prepareToolsDamageData(result.data)
                });
            },


            loadData() {
                this.loadAllReportedTools();
            },

            damageClick(event) {
                console.log(event);
                this.showToolDamageConfirmModal(event.rowData);
            },

            showToolDamageConfirmModal(selected) {
                this.selectedToolDamage = selected;
                this.modalToolDamageConfirm.show();
            },

            deleteSelectedTool() {
                return;
            },

            confirmedToolDamage() {
                this.loadData();
            },

            refreshData() {
                this.loadData();
            },

        },


        created() {
        },

        mounted() {
            this.loadData();
            this.modalToolDamageConfirm = new Modal(document.getElementById('modalToolDamageConfirm'));
        },


    }
</script>

<style>

    .all-tool-transfers-grid table tr {
        cursor: pointer;
    }

    .e-headercelldiv {
        margin-right: 5px !important;
        padding-right: 0 !important;
    }

    .all-tool-damages-grid table tr .reported-status {
        background: #6aff00 !important;
        /* color: #666666 !important; */
    }



</style>
