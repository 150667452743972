<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form id="tool-damage-form" @submit.prevent="">
                        <div v-if="toolDamage" class="d-grid">

                            <div class="text-center top-avatar images-cover">
                                <router-link :to="{ name: 'tool-profile', params: { toolId: toolDamage.tool }}" >
                                    <h2>{{toolDamage.tool_obj.name}}</h2>
                                </router-link>

                                <silent-box :gallery="toolImagesSBox"></silent-box>

                                <!-- <input type="checkbox" id="zoomCheck">
                                <label for="zoomCheck" class="float-start">
                                    <img class="tool-image-top mb-3" v-if="image_url" :src="image_url" alt="" tabindex="0">
                                    <img class="tool-image-top mb-3" v-else-if="toolDamage.tool_obj.image_url" :src="toolDamage.tool_obj.image_url" alt="" :tabindex="0">
                                </label> -->


                            </div>

                            <h4 class="mb-0">Prijavio:
                                <router-link :to="{ name:'ProfilePage', params:{'username': reported_by.username} }" target="_blank" ><b>{{userFullName(reported_by)}}</b></router-link>
                            </h4>
                            <h4 class="mb-3">Oštetio:
                                <router-link v-if="damaged_by" :to="{ name:'ProfilePage', params:{'username': damaged_by.username} }" target="_blank" ><b>{{userFullName(damaged_by)}}</b></router-link>
                                <span v-else>? (Bio u magacinu)</span>
                            </h4>

                            <template v-if="tool_damages">
                                <h4 class="mb-0">Prethodna oštećenja:</h4>
                                <tool-damages-accordion :damages="tool_damages" />
                            </template>
                            <template v-else>
                                <h4>Nema prethodnih oštećenja</h4>
                            </template>

                            <hr>

                            
                            <h4><strong>Novo oštećenje</strong></h4>
                            <p>{{toolDamage.description}}</p>


                            <div class="text-center images-cover">
                                <silent-box :gallery="toolDamageSBox"></silent-box>

                                <!-- <input type="checkbox" id="zoomDamageImg">
                                <label for="zoomDamageImg">
                                    <img class="tool-image-damage mb-3" v-if="toolDamage.image_url" :src="toolDamage.image_url" alt="" :tabindex="0">
                                </label> -->
                            </div>


                            <div class="btn-group" role="group" aria-label="Prihvatanje">
                                <input v-model="toolDamage.damage_status" value="REJECTED" type="radio" class="btn-check" name="damage_status" id="reject-damage" autocomplete="off" checked>
                                <label class="btn btn-lg btn-outline-primary p-3" for="reject-damage">Poništi</label>

                                <input v-model="toolDamage.damage_status" value="CONFIRMED" type="radio" class="btn-check" name="damage_status" id="confirm-damage" autocomplete="off">
                                <label class="btn btn-lg btn-outline-primary p-3" for="confirm-damage">Potvrdi</label>

                            </div>


                            <div v-if="toolDamage.damage_status === 'CONFIRMED' && tool">
    
                                <h5 class="mt-4 mb-0">Nova vrednost alata</h5>
                                <div class="mt-3 form-floating">
                                    <input v-model="tool.value" step=".01" class="form-control" type="number"  placeholder="5000 RSD" aria-label="Proizvodjač">
                                    <label for="floatingInput">Vrednost alata (RSD):</label>
                                    <div class="invalid-feedback">
                                        Vrednost alata je obavezno polje.
                                    </div>
                                </div>
                            </div>


                            <!-- TODO: TESTIRATI KADA SE UPDATUJE TABELA JEL SE SJEBE PROP PA I MODAL -->

                            <!-- <form id="warehouse-form">
                                <div v-if="warehouse" class="form-floating">
                                    <input v-model="warehouse.name" class="form-control" type="text" placeholder="Naziv:" aria-label="Naziv skladišta alata" required>
                                    <label for="floatingInput">Naziv skladišta alata:</label>
                                    <div class="invalid-feedback">
                                        Naziv je obavezno polje.
                                    </div>
                                </div>

                            </form> -->
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="submitDamage()" :disabled="toolDamage && toolDamage.damage_status === 'REPORTED'" type="button" class="btn btn-primary"> Sačuvaj </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import ToolsService from '@/service/ToolsService.js';
    import UtilService from '@/service/UtilService.js';
    import {mapState, mapGetters} from 'vuex'
    import ToolDamagesAccordion from '@/components/misc/tool-damages-accordion.vue';
    import ToolsService from '@/service/ToolsService.js';

    // import QrcodeVue from 'qrcode.vue'

    export default {

        props: {
            modalId: String,
            title: String,
            toolDamage: Object,
            thisModal: Object
        },

        components: {
            ToolDamagesAccordion
            // QrcodeVue,
        },
        
        data() {
            return {
                transfer_accept_status: undefined,

                comment: "",
                image_url: undefined,
                file: [],
                tool: null,



            }
        },

        computed: {
            ...mapState([
                'toolTypes',
                'warehouses',
                'user',
                'userAssignment',
                'test'
            ]),

            ...mapGetters([
                'isAdmin',
                'isManager'
            ]),

            damaged_by() {
                if (this.toolDamage.damaged_by === null) return null;
                return this.toolDamage?.damaged_by_obj;
            },

            reported_by() {
                return this.toolDamage?.reported_by_obj;
            },

            tool_damages() {
                if (this.tool && this.tool.damages) return this.tool.damages;
                return [];
            },



            toolImagesSBox() {
                let images = []
                let imageId = 1;
                if (this.toolDamage.tool_obj?.image_url){
                    let obj = {
                        src: this.toolDamage.tool_obj?.image_url,
                        thumbnailHeight: "200px",
                        thumbnailWidth: "200px",
                        description: "Slika " + imageId++
                    }

                    images.push(obj)

                    this.toolDamage.tool_obj?.gallery?.forEach(img => {
                        let obj = {
                            src: img.url,
                            thumbnailHeight: "200px",
                            thumbnailWidth: "200px",
                            description: "Slika " + imageId++
                        }

                        images.push(obj)
                    });
                }
                return images

            },

            toolDamageSBox() {
                if (!this.toolDamage.image_url) return []

                return [{
                    src: this.toolDamage.image_url,
                    thumbnailHeight: "300px",
                    thumbnailWidth: "300px",
                    description: this.toolDamage.description
                }]
            }


        },

        methods: {

            userFullName : UtilService.userFullName,

            validate() {
                let form = document.getElementById("tool-damage-form");
                form.classList.add('was-validated');

                if (!form.checkValidity())
                    return false;

                return true;
            },

            submitTransfer() {
                if (!this.validate()) return

                this.createTransfer();
            },

            submitDamage() {
                if (!this.validate()) return

                if (this.toolDamage.damage_status === 'CONFIRMED') {
                    ToolsService.confirmToolDamage({id: this.toolDamage.id, new_value: this.tool?.value}).then(() => {
                        this.thisModal.hide();
                        this.$emit('confirmed');
                    })
                }
                else if (this.toolDamage.damage_status === 'REJECTED') {
                    ToolsService.rejectToolDamage({id: this.toolDamage.id}).then(() => {
                        this.thisModal.hide();
                        this.$emit('confirmed');
                    })
                } else {
                    alert("Izaberite satus");
                }

            },

            loadTool() {
                ToolsService.getTool({id: this.toolDamage.tool, damages_confirmed: true, services:true}).then(response => {
                    this.tool = response.data;
                }).catch(() => {
                    alert("Neuspesno ucitavane")
                });
            },
           
            initData() {
                this.loadTool();
            },

            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.initData();
                })
            },
        },

        mounted() {
            this.setUpListener();
        },

        created() {
        }

    }
</script>

<style scoped>
.v-select.form-control{
    height: 4.5em;
}

.tool-image-top {
    transition: all 0.25s ease;
    cursor: zoom-in;
    max-width: 100px;
    max-height: 500px;
    border-radius: 100%;
}

.arrow-down-cricle {
    padding: 15px 21px 12px;
    display: inline-block;
    border-radius: 100%;
}

.arrow-down-cricle-success {
    border: 1px solid green;
    background: #8cff00;
}

.text-area-floating {
    height: 8em;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox]:checked ~ label > .tool-image-top {
    cursor: zoom-out;
    max-width: 100%;
    border-radius: 0;

}


.tool-image-damage {
    max-width: 100px;
    max-height: 500px;
    border-radius: 100%;

    transition: all 0.25s ease;
    cursor: zoom-in;
}

input[type=checkbox]:checked ~ label > .tool-image-damage {
    cursor: zoom-out;
    max-width: 400px;
    border-radius: 0;

}
</style>
